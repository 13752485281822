<script lang="ts">
import type { PropType } from "vue";
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import type { Role } from "@/models/options";
import {
  LENDFLOW_ROLE_GROUP,
  CLIENT_ROLE_GROUP,
  FUNDER_ROLE_GROUP
} from "@/helpers/constants";
import type { IUser } from "@/models/users";

export default defineComponent({
  name: "AccessControl",
  props: {
    lendflow: {
      type: Boolean,
      default: false
    },
    clients: {
      type: Boolean,
      default: false
    },
    funders: {
      type: Boolean,
      default: false
    },
    roles: {
      type: Array as PropType<Role[]>,
      default: () => []
    },
    exclude: {
      type: Array as PropType<Role[]>,
      default: () => []
    }
  },
  setup(props, { slots }) {
    const { getters } = useStore();
    const user = computed<IUser | null>(() => getters["auth/user"]);
    const allowedUserRoles = computed(() => {
      let roles: Role[] = [];

      if (props.lendflow) {
        roles = [...roles, ...LENDFLOW_ROLE_GROUP];
      }
      if (props.clients) {
        roles = [...roles, ...CLIENT_ROLE_GROUP];
      }
      if (props.funders) {
        roles = [...roles, ...FUNDER_ROLE_GROUP];
      }
      if (props.roles.length) {
        roles = [...roles, ...props.roles].filter((r) =>
          props.roles.includes(r)
        );
      }
      if (props.exclude.length) {
        if (!roles.length) {
          roles = [
            ...LENDFLOW_ROLE_GROUP,
            ...CLIENT_ROLE_GROUP,
            ...FUNDER_ROLE_GROUP
          ];
        }
        roles = roles.filter((role) => !props.exclude.includes(role));
      }

      return Array.from(new Set(roles));
    });

    return () => {
      if (
        !allowedUserRoles.value.length ||
        Object.values(user.value?.roles || {}).some((role) =>
          allowedUserRoles.value.includes(role)
        )
      ) {
        return slots.default?.();
      }
      return null;
    };
  }
});
</script>
