export enum NoteState {
  Closed = "closed",
  Static = "static",
  Draggable = "draggable"
}

export enum NoteType {
  Application = "application",
  Business = "business",
  Call = "call"
}

export enum NotableType {
  "App\\Models\\Application" = NoteType.Application,
  "App\\Models\\Business" = NoteType.Business,
  "App\\Models\\CallSession" = NoteType.Call
}
